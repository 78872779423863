.list,
.columns {
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    .item {
        width: 100%;
        padding: 1em 2em;
        box-sizing: border-box;
        border-bottom: solid 1px rgb(224, 224, 224);
        display: flex;
        flex-direction: row;

        &:last-child {
            border-bottom: none;
        }

        .type {
            width: 5%;
            text-align: center;

            img {
                width: 40px;
            }
        }

        .content {
            width: 40%;
            padding: 0 0.3em;
            height: 4em;
        }
        .date {
            width: 15%;
            text-align: center;
        }

        .creator {
            width: 10%;
            text-align: center;
            justify-content: center;
            display: flex;
            .picture {
                width: 40px;
                height: 40px;
                border-radius: 20px;
            }
        }

        .contributors {
            width: 15%;
            position: relative;
            display: flex;
            flex-direction: row;
            text-align: center;

            .avatars {
                justify-content: center;
            }
        }
        .tags {
            width: 15%;
            text-align: left;

            .MuiChip-root {
                margin-right: 0.3em;
            }

            .tag {
                display: inline-block;
                border-radius: 0.2em;
                padding: 0.2em 0.5em 0.2em 1em;
                color: #fff;
                margin-right: 0.4em;
                margin-bottom: 0.2em;
                position: relative;
                display: inline-flex;
                align-items: center;
                overflow: hidden;
                font-size: 0.9em;

                &:nth-child(2) {
                    background-color: $secondary;
                }

                &:nth-child(3) {
                    background-color: $third;
                }

                &::before {
                    content: "";
                    display: block;
                    background-color: #fff;
                    border-radius: 2em;
                    position: absolute;
                    width: 0.8em;
                    height: 0.8em;
                    left: -0.4em;
                }
            }

            .tagsLeft {
                color: rgb(169, 169, 169);
            }
        }

        .actions {
            //display: none;
            text-align: right;
            align-items: flex-end;
            width: 15%;

            a {
                margin-right: 2em;
                display: inline-block;
            }
        }

        .due_date,
        .size,
        .priority,
        .status {
            width: 15%;
        }

        .due_date {
            .normal {
                color: grey;
            }

            .past {
                color: rgb(214, 50, 50);
            }
        }

        .status,
        .priority {
            .tag {
                //color: #fff;
                display: inline-block;
                //padding: 0.3em 1em;
                text-transform: uppercase;
                //border-radius: 0.2em;
                font-size: 0.8em;
                //border: solid 1px grey;

                &.assigned {
                    //color: $assigned;
                    border-color: $assigned;
                }
                &.started {
                    //color: $start;
                    border-color: $start;
                }
                &.review {
                    //color: $review;
                    border-color: $review;
                }
                &.done {
                    //color: $done;
                    border-color: $done;
                }

                &.low {
                    //color: $low;
                    border-color: $low;
                }
                &.mid {
                    //color: $mid;
                    border-color: $mid;
                }
                &.high {
                    //color: $high;
                    border-color: $high;
                }
            }
        }

        .task {
            width: 40%;
        }

        .function {
            width: 5%;
        }

        .company {
            width: 30%;
        }

        .email {
            width: 20%;
        }
        .lastname,
        .firstname,
        .phone {
            width: 15%;
        }

        .open_details_action,
        .open_file_action {
            color: $colorScheme3;
            transition: 0.3s;
            cursor: pointer;

            &:hover {
                color: $colorScheme1;
                text-decoration: underline;
            }
        }

        a {
            cursor: pointer;
            color: $colorScheme3;
            transition: 0.3s;

            &:hover {
                color: $colorScheme1;
            }
        }
    }

    .item_with_subrows {
        border-bottom: solid 1px rgb(224, 224, 224);
        padding: 1em 2em;

        &:last-child {
            border-bottom: none;
        }

        .item {
            padding: 0;
            margin-bottom: 0.5em;
            border-bottom: solid 1px $lightGrey;
            padding-bottom: 0.4em;
        }

        .subrows {
        }
    }
}
