.user {
    .pageName {
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        position: relative;

        .goBack {
            background-color: transparent;
            color: $primary;
            border: none;
            display: flex;
            align-items: center;
            margin: 1em 3em 0.5em 0;
            cursor: pointer;

            svg {
                margin-right: 0.3em;
            }
        }

        .saveButton {
            position: absolute;
            right: 0;
            top: 2em;
        }
    }
}

.userHeader {
    display: flex;
    flex-direction: row;
    align-items: center;

    .userPicture {
        width: 3em;
        height: 3em;
        border-radius: 4em;
        margin-right: 1em;
        background-color: $lightGrey;
    }
}

.userInfos {
    .card {
        .title {
            display: block;
            width: 100%;
            padding: 1em;
            box-sizing: border-box;
            border-bottom: solid 1px rgb(214, 214, 214);
            font-size: 1.2em;
            color: $primary;
            position: relative;

            .saveHeaderButton {
                position: absolute;
                right: 1em;
                bottom: 0.5em;
            }
        }

        .input {
            width: 100%;
            margin: 0.5em 0;
        }
    }
}

/** dialog confirm */

.dialog {
    padding: 2em;
    text-align: center;
    width: 400px;
    max-width: 90%;

    .actions {
        margin-top: 1em;
        padding-top: 1em;
        border-top: solid 1px rgb(207, 207, 207);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}
/** scan */
.scan {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding: 2em;
    background-color: #fff;
    text-align: center;

    svg {
        transform: scale(2);
        margin-bottom: 0.5em;
        color: rgb(183, 183, 183);
    }
    .separator {
        font-size: 1.2em;
        margin: 1em 0;
        display: block;
        text-align: center;
    }

    .MuiTextField-root {
        margin: 1em 0;
        width: 100%;
    }
}

.modalHeader {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 92%;
    background-color: $primary;

    span {
        font-size: 0.7em;
        font-weight: 300;

        &.title {
            font-size: 20px;
            font-weight: 500;
        }
    }
    &.error {
        background-color: rgb(255, 96, 96);
    }

    &.success {
        background-color: rgb(114, 181, 75);
    }
}

.modalContent {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.form {
        min-width: 50vw;
    }

    .info {
        color: #6e6e6e;
        font-style: italic;
        display: block;
        margin: 10px 0;
    }

    .message {
        padding: 0.5em;
        background-color: #e4e4e4;
        text-align: center;
        border-radius: 4px;
        margin: 10px 0;
        width: 90%;

        &.error {
            background-color: rgb(255, 208, 208);
            color: rgb(255, 96, 96);

            &.list {
                text-align: left;
                max-height: 300px;
                overflow-y: auto;
            }
        }

        &.success {
            background-color: rgb(233, 255, 220);
            color: rgb(86, 145, 52);
        }
    }
    .modalItemCol {
        display: flex;
        flex-direction: column;
    }

    .validCode {
        font-size: 20px;
        display: block;
        font-weight: bold;
        color: rgb(114, 181, 75);
        margin-top: 1em;
    }
    .errorCode {
        font-size: 20px;
        display: block;
        font-weight: bold;
        color: rgb(255, 96, 96);
        margin-top: 1em;
    }

    .errorIndicator {
        display: block;
        text-align: center;
        color: grey;
        margin-top: 0.4em;
        font-size: 0.9em;
    }

    .closeButton {
        margin: 2em 0;
    }
}
