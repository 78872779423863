.MuiButton-containedPrimary:not(.Mui-disabled) {
    background-color: $colorScheme3 !important;
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
    color: $secondary !important;

    &.MuiButton-outlined {
        border-color: $secondary !important;
    }

    &.MuiButton-textError {
        color: $primary !important;

        &.MuiButton-outlined {
            border-color: $primary !important;
        }
    }
}

.errroNotification {
    .MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
        color: #fff !important;
    }
}

.MuiModal-backdrop:not(.MuiBackdrop-invisible) {
    background-color: rgba($secondary, 0.9) !important;
}
