.tableComponent {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;

    .table {
        height: 100%;
    }
    .tableHeader {
        width: 100%;
        height: 5%;
        background-color: rgb(33, 33, 33);
        display: flex;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;

        .column {
            padding: 10px 5px;
            color: #d5d5d5;
            font-weight: 500;
            font-size: 0.9em;
            display: flex;
            align-items: center;
            position: relative;

            &.sortable {
                cursor: pointer;
                .sort {
                    position: absolute;
                    right: 5px;
                }
            }
            &.min {
                flex: 0.5;
            }

            &.center {
                justify-content: center;
                text-align: center;
            }
        }
    }

    .tableBody {
        width: 100%;
        height: 90%;
        overflow: auto;
        background-color: #fff;
        border-top: solid 1px $lightGrey;
        border-bottom: solid 1px $lightGrey;

        .row {
            width: 100%;
            display: flex;
            border-bottom: solid 1px $lightGrey;
            align-items: center;

            &:nth-child(2n) {
                background-color: $lightGrey;
            }
            .entry {
                padding: 5px;
                color: $darkGrey;
                font-weight: 400;
                font-size: 0.9em;
                border-right: solid 1px $lightGrey;

                &:last-child {
                    border-right: none;
                }

                &.center {
                    text-align: center;
                }

                &.min {
                    flex: 0.5;
                }

                .entry_value {
                    width: 100%;
                    height: 100%;
                    min-height: 15px;
                    cursor: pointer;
                }
                .entry_edit {
                    width: 100%;
                    input {
                        width: 100%;
                        padding: 5px;
                        box-sizing: border-box;
                        border: 2px solid #b8b8b8;
                        border-radius: 0.2em;
                        transition: 0.3s;
                        font-size: 1em;
                        color: $darkGrey;

                        &:focus {
                            border-color: $primary;
                            outline: none;
                        }
                    }
                }
                .flagButton {
                    svg {
                        font-size: 1.4em;
                        color: rgb(198, 198, 198);
                        cursor: pointer;
                    }

                    &.active {
                        svg {
                            color: $success;
                        }
                    }
                }

                a {
                    color: $darkGrey;
                    transition: 0.3s;

                    &:hover {
                        color: $primary;
                    }
                }

                .action {
                    cursor: pointer;
                    background-color: transparent;
                    border: none;

                    color: $darkGrey;
                    transition: 0.3s;

                    &:hover {
                        color: $primary;
                    }

                    svg {
                        transform: scale(0.8);
                    }

                    &.delete {
                        color: $error;
                    }
                }
            }

            .actions {
                width: 100%;

                button {
                    display: inline-block;
                    min-width: 48%;
                    padding: 2%;

                    &.error {
                        color: $error !important;
                    }

                    svg {
                        font-size: 1.4em;
                    }
                }
            }
        }
    }

    .tableFooter {
        padding: 15px;
        height: 6%;
        background-color: #fff;
        box-sizing: border-box;

        .pagination {
            display: flex;
            flex-direction: row;
            align-items: center;

            button,
            span {
                cursor: pointer;
                transition: 0.3s;
                background-color: transparent;
                width: 45px;
                font-size: 0.9em;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                margin: 0 0.1em;

                &:hover {
                    background-color: #eee;
                    color: $primary;
                    border-radius: 0.2em;
                }

                &.disabled {
                    color: rgb(181, 181, 181);
                    cursor: initial;
                    &:hover {
                        background-color: buttonface !important;
                    }
                }
            }

            .active {
                cursor: default;
                color: #fff;
                background-color: $primary;
                border-radius: 0.2em;

                &:hover {
                    background-color: $primary;
                }
            }
        }

        @media all and (max-width: 1450px) {
            height: 10%;

            button,
            span {
                height: 25px !important;
            }
        }
    }
}

.tableLoader {
    position: relative;
    width: 100%;
    .loader {
        width: 100% !important;
        height: 100% !important;
        position: relative;
        background-color: transparent !important;
        padding-top: 2em;
    }
}
