@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

@import "theme";

@import "Modules/header";
@import "Modules/navigation";
@import "Modules/page";
@import "Modules/lightbox";
@import "Modules/dashboard";
@import "Modules/movies";
@import "Modules/users";
@import "Modules/screens";

@import "Modules/Account/login";
@import "Modules/Account/subscription";
@import "Modules/Account/account";

@import "Modules/Common/modals";
@import "Modules/Common/editor";
@import "Modules/Common/list";
@import "Modules/Common/table";

@import "Utils/flexbox";
@import "Utils/fields";
@import "Utils/datagrid";
@import "Utils/mui";
@import "Utils/dropUploader";
@import "Utils/medias";

/* Global styles */

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-feature-settings: "pnum";
  font-size: 14px;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  color: #3b3b3b;
}
.emptyMessage {
  color: rgb(217, 217, 217);
  text-align: center;
  padding: 2em;
  display: block;
  font-size: 2em;
  font-weight: 300;
}

.error_message {
  color: $error;
}
.success_message {
  color: $success;
}

.applicationContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  padding: 0 2em 0 0;
  overflow: hidden;
  background-color: $background;

  @media all and (max-width: 768px) {
    padding: 0;
  }
}

.spacer {
  width: 20px;
}
.separator {
  width: 100%;
  height: 1px;
  background-color: rgb(225, 225, 225);
  margin: 1em 0;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  position: fixed;
  z-index: 9;
  background-color: #fff;
  svg {
    color: grey;
  }
}

.lost {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    font-size: 5em;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 0.4em;
  }

  span {
    font-size: 2em;
    color: #c2c2c2;
    display: inline-block;
    margin-bottom: 0.4em;
  }
  a {
    color: $primary;
  }
}
/* Font weight */

strong,
h2,
th {
  font-weight: 600;
}
/* scrollbar */

/* width */
body {
  scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select_label {
  color: $primary;
  font-size: 0.7em;
  padding: 0.3em 1em;
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 4em;
  top: 0em;
  z-index: 2;
  margin-bottom: -0.4em;
  left: 0.5em;
}

.appLoader {
  position: fixed !important;
  top: 0;
  width: 100vw;
  z-index: 99 !important;
  background-color: rgba($primary, 0.2) !important;
  transition: 0.3s;

  &.done {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4px);
  }

  span {
    background-color: $primary;
  }
}

.loading_skeleton {
  padding: 3em 0;
  opacity: 0.5;

  .row {
    flex: 0.05;
  }
}

.deviceView {
  display: none;

  @media all and (max-width: 991px) {
    display: flex;
    box-sizing: border-box;
    padding: 3em;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    img {
      width: 10em;
      max-width: 90%;
    }
  }
}

.desktopView {
  display: block;
  @media all and (max-width: 991px) {
    display: none;
  }
}

/* Global styles */

.link {
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  color: #626262;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #888;
  box-shadow: 1px 1px 10px -7px rgb(0, 0, 0);
  transition: 0.1s;

  &:hover {
    box-shadow: 0px 4px 10px -7px rgb(0, 0, 0);
  }

  &.add {
    background-color: $success;
    color: #fff;
  }
}
