.tableTitle{
    display: flex;

    img{
        width:80px;
        height:100px;
        object-fit: cover;
        background-color: $lightGrey;
        margin-right:1em;
    }

    .movieInfos{
        display: flex;
        flex-direction: column;
        text-align: left;

        .movieTitle{
            font-size: 1.3em;
            color:$darkGrey;
        }

        .movieTitleOriginal{
            font-size: 1em;
            color:$midGrey;
        }

        .producer{
            margin-top:.2em;
            cursor: pointer;
        }

        .actors{
            font-style: italic;
            font-size: .9em;
            color:$midGrey;
        }
    }
}