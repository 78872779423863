.login_container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;

    .form {
        width: 30%;
        height: 100%;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        .content {
            width: 70%;
            min-height: 50%;
            padding: 2em;
            box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);

            .switch_account_action {
                text-align: center;
                margin-bottom: 2em;

                strong {
                    display: block;
                    font-size: 1.7em;
                    margin-bottom: 0.3em;
                    font-weight: 600;
                    color: rgb(37, 37, 37);
                }

                span,
                a {
                    font-size: 0.9em;
                }

                span {
                    color: grey;
                }

                a {
                    color: $primary;
                    margin-left: 12px;
                }

                &.success {
                    svg {
                        fill: rgb(79, 191, 31);
                        font-size: 5em;
                        margin-top: 1em;
                        margin-bottom: 0.2em;
                    }
                }
            }

            .fields {
                margin: 0.4em 10%;

                > div{
                    width:100%;
                }
                fieldset {
                    border-color: rgb(238, 238, 238);
                    border-width: 3px;
                }
            }  

            .actions {
                padding: 0em 0 2em 0;

                a {
                    color: rgb(87, 82, 103) ;
                    font-size: 0.9em;
                    float: right;
                    text-align: left;
                    transition: 0.3s;

                    &:hover {
                        color: $primary;
                    }
                }

                button {
                    margin-top: 1em;
                    //background-color: $primary;

                    svg {
                        color: #fff;
                        transform: scale(0.4);
                    }

                    &.Mui-disabled {
                        svg {
                            color: $secondary;
                        }
                    }
                }

                .error {
                    margin-top: 1em;
                }
            }

            .connect_with {
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(119, 119, 119);
                font-size: 0.9em;
                font-weight: 500;

                &::after,
                &::before {
                    content: "";
                    display: block;
                    width: 4em;
                    height: 2px;
                    background-color: rgb(218, 218, 218);
                    margin: 1em;
                }
            }
            .oauth_connect {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1em;
                transition: 0.3s;

                span {
                    color: $midGrey;
                    margin-right: 0.5em;
                }
                .login_button {
                    cursor: pointer;
                    margin: 0 0.5em;
                    &.google {
                        color: $google;
                    }
                    &.facebook {
                        color: $facebook;
                    }

                    svg {
                        transition: 0.3s;
                        transform: scale(1.4);
                    }

                    &:hover {
                        svg {
                            transform: scale(1.5);
                        }
                    }
                }
            }
        }
    }

    .presentation {
        width: 70%;
        height: 100%;
        background-size: cover;
        background-position: center;
        display:flex;
        align-items: center;
        justify-content: center;
        //opacity: 0.4;
    }
}

.cgu_accept {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;

    span {
        font-size: 1em;
        color: $darkGrey;
    }

    a {
        font-size: 1em;
        color: $primary;
        margin-left: -0.8em;
    }
}
