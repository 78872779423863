.mediaList,
.medias {
    width: 100%;
    height: 100%;
    max-height: 60vh;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 10em;
    }
    .picture_table {
        height: 80px;
        margin: 4px;
        position: relative;
        transition: 0.3s;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            position: relative;
            transition: 0.1s;
            border: solid 2px rgb(207, 207, 207);
            margin-right:10px;
        }

        span{
            font-weight: bold;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            width:25vw;
        }
    }
}

.medias {
    list-style: none;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    max-height: none;
    height: auto;
    overflow: hidden;
    li {
        flex: 1;
        width: 98% !important;
        padding: 10px;
        border: none;
        box-sizing: border-box;
        border-bottom: solid 1px #eee;
        display: flex;
        flex-direction: row;

        img {
            width: 100px !important;
            height: 100px !important;
        }

        .infos {
            flex-grow: 1;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            strong {
                font-size: 1.2em;
                margin-bottom: 10px;
            }
        }
        .icons {
            display: flex;

            width: 100px;
            flex-direction: row;
            align-items: center;

            svg {
                width: 30px !important;
                height: 30px !important;
                margin: 10px;
            }
            .delete {
                position: relative;
            }
        }
    }
}

.selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    strong {
        flex: 0.5;
    }

    span {
        flex: 0.4;
        color: grey;
    }

    .select_button {
        flex: 0.1;
        text-align: right;
    }
}

.lightbox {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;

    opacity: 0;
    visibility: hidden;

    &.open {
        opacity: 1;
        visibility: visible;
    }
}
