.navigation {
    flex: 0.1;
    width: 10vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding-bottom: 2em;
    background-color: rgb(33, 33, 33);
    padding: 2em 0;
    position: relative;

    @media all and (max-width: 1450px) {
        flex: 0.15;
        width: 15vw;
    }

    .logo {
        width: 80%;
        height: auto;
    }

    .menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 4em;

        a {
            padding: 1em;
            border: none;
            background-color: transparent;
            color: $midGrey;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 500;
            letter-spacing: 0.2px;
            transition: 0.3s;

            &.subMenu {
                padding: 0 1em;

                svg {
                    opacity: 0;
                }
            }
            &:hover {
                color: #fff;
            }
            img {
                width: 50px;
                height: 50px;
                transition: 0.3s;
            }

            svg {
                color: $midGrey;
                transition: 0.3s;
                font-size: 1.6em;
                margin-right: 1em;
            }

            &:hover {
                svg {
                    color: #fff;
                }
            }
            &.active {
                svg {
                    color: $primary;
                }
                color: #fff;
            }
        }
    }

    @media all and (max-width: 768px) {
        width: 5em;

        .userConnected {
            width: 2em;
            height: 2em;
        }
    }
}

.userConnected {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: $midGrey;
    border-radius: 3em;

    transition: 0.3s;

    &:after {
        position: absolute;
        display: none;
        content: "";
        width: 12px;
        height: 12px;
        background-color: $colorScheme8;
        border-radius: 30px;
        bottom: 0px;
        right: 4px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }
    &:hover {
        transform: scale(1.1);
    }
}
