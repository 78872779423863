.greeting {
    padding-bottom: 2em;
    .row {
        align-items: center;
        font-size: 1.1em;
        img {
            margin-right: 0.4em;
            width: 3.5em;
            height: 3.5em;
            border-radius: 4em;
        }

        .col {
            strong {
                color: $darkGrey;
            }

            .name {
                align-items: flex-end;
                span {
                    color: $midGrey;
                    font-size: 1.3em;
                }
                a {
                    position: relative;
                    bottom:-3px;
                    margin-left:1em;
                    color: $midGrey;
                    transition: .3s;

                    svg{
                        transform: scale(.8);
                    }

                    &:hover{
                        color:$darkGrey;
                        transform:rotate(45deg);
                    }
                }
            }
        }
    }
}

.module {
    background-color: #fff;
    box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
    padding: 1em;
    border-radius: 0.4em;

    .module_header {
        border-bottom: solid 1px rgb(222, 222, 222);
        padding-bottom: 0.5em;
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.3em 0;

        strong {
            width: 60%;
            font-weight: 400;
            color: rgb(80, 80, 80);
            flex-grow: 1;
        }

        span {
            text-align: right;
            width: 8%;
            display: none;
            color: rgb(190, 190, 190);
            font-size: 0.8em;
        }

        .add_project_button {
            font-size: 0.8em;

            svg {
                font-size: 1.6em;
                position:relative;
                left:-.2em;
                top:-.15em;
            }
        }
    }

    .module_content {
        .item {
            border-bottom: solid 1px rgb(232, 230, 230);
            padding: 0.4em 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;

            .thumb {
                width: 5%;
                max-width: 2em;
                height: 2em;
                background-color: $colorScheme4;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: 500;
                border-radius: 0.5em;
                margin-right: 1em;
            }

            .name {
                text-decoration: none;
                width: 70%;
                color: rgb(46, 46, 46);
                font-weight: 500;
            }

            .actions {
                width: 25%;
                button {

                    $colorBtn : $colorScheme3;

                    justify-self: flex-end;
                    float: right;

                    border: solid 2px $colorBtn;
                    color: $colorBtn;
                    background-color: transparent;
                    cursor: pointer;
                    transition: 0.3s;
                    padding: 0.3em 1em;
                    border-radius: 0.3em;
                    font-weight: 500;

                    &:hover {
                        background-color: $colorBtn;
                        color: #fff;
                    }

                    &.delete{
                        color:$error;
                    }
                }
            }
        }
    }
}

.start_project {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 4em;
        height: 4em;
    }
    strong {
        font-size: 2em;
        color: $midGrey;
        padding: 1em 0;
    }
}
