.Page.account {
    header {
        .pageName {
            .avatar {
                margin-top: 1em;
                width: 5em;
                height: 5em;
                border-radius: 3em;
                overflow: hidden;
                background-color: $lightGrey;
 
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .infos {
                margin-left: 1em;
                display: flex;
                flex-direction: column;

                strong {
                    color: $secondary;
                    font-weight: 500;
                }
                .plan {
                    opacity: 0.8;
                    margin-top: 0.4em;
                    background-color: $primary;
                    color: #fff;
                    width: 5em;
                    font-size: 0.8em;
                    text-transform: uppercase;
                    padding: 0.2em;
                    border-radius: 0.2em;
                    text-align: center;
                }
            }
        }

       
    }
}

.settings_card {
    box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03) !important;
    margin-bottom: 0.5em;
    overflow: initial !important;

 
    .MuiCardHeader-content {
        border-bottom: solid 1px #f0f0f0;

        span {
            font-size: 1.2em !important;
        }
    }

    strong.title{
        border-bottom: solid 1px $lightGrey !important;
        margin-bottom:.4em;
        padding-bottom:.2em;
        color:$midGrey;
        font-weight: 400;
    }

    .settings_actions {
        padding: 1em !important;
        button {
            margin: 0px 0px 0px auto;
        }
    }

    .delete_content {
        display: flex;
        .delete_button {
            color: $primary;
            border: none;
            background: transparent;
            margin: 0 0 0 auto;
            display: inherit;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                color: $error;
            }
        }
    }
}

.lock_provider {
    color: $midGrey;
    font-style: italic;
    font-size: 0.8em;
    padding: 0 0.3em;
}
