.row{
    display: flex;
    flex-direction: row; 
    flex:1;
    &.reverse{
        flex-direction: row-reverse;
    }

    .col{
        position: relative;
        margin:0 .2em;

        &:first-child{
            margin-left:0;
        }

        &:last-child{
            margin-right:0;
        }
    }

    @media all and (max-width:991px){
        flex-direction: column;
    }
}

.col{
    display:flex;
    flex-direction: column;
    flex:1;

    &.reverse{
        flex-direction: column-reverse;
    }
    &.md4{
        width:25%;
        flex:.25;
    }
    &.md6{
        width:50%;
        flex:.5;
    }
    &.md8{
        width:75%;
        flex:.75;
    }
}
