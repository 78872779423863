.link_screen {
    color: $primary;
    margin-right: 1em;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;

    &:hover {
        color: grey;
    }
}
.screens_file_list {
    width: 100%;
    list-style: none;
    padding: 0;
    border-radius: 3px;
    border: solid 1px rgb(232, 232, 232);
    height: 100%;
    overflow-y: auto;

    .item-container {
        &:nth-child(2n) {
            background-color: #ffffff;
        }
    }

    li {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 5px;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 4px;

            span {
                &.position {
                    flex: 0.1;
                    font-size: 1.3em;
                    width: 40px;
                    text-align: center;
                }
            }
            img {
                flex: 0.2;
                max-width: 60px;
                object-fit: cover;
                margin-right: 40px;
            }
            .picture_name {
                flex: 0.6;
            }

            .action {
                flex: 0.2;
                text-align: right;
                float: right;

                button {
                    background-color: transparent;
                    border: none;
                    color: red;
                    cursor: pointer;

                    &.edit_button {
                        color: $primary;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.image_modal {
    .MuiDialog-paper {
        width: 1600px;
        max-width: 90vw;
    }
}
.warning_modal {
    z-index: 9999999999;
}
.image_details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    position: relative;
    height: 650px;
    max-height: 95vh;

    img {
        position: relative;
        width: 45%;
        height: 100%;
        object-fit: cover;
    }
    .info {
        width: 55%;
        box-sizing: border-box;
        padding: 1em;
        height: 100% !important;
        position: relative;
        overflow: hidden;
        padding-bottom: 5em;
        margin: 0;
        font-style: normal;

        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: transparent;
            height: auto;
            padding: 10px;
            box-sizing: border-box;

            .title {
                flex: 0.8;
            }
        }

        .title {
            text-wrap: wrap;
            font-size: 1.3em;
            height: 30px;
        }

        .hide_details {
            display: block;
            position: absolute;
            top: 25%;
            height: 90%;
            width: 100%;
            left: 0;
            background-color: #fff;
            opacity: 0.5;
            z-index: 44;
        }
        .switch_field {
            padding: 0.3em 1em;
            background-color: #f2f2f2;
            text-align: right;
            border-radius: 3px;
        }
        .days_details {
            margin-top: 2px;
            padding: 0.3em 1em;
            background-color: #eee;
        }

        .hours_details,
        .image_infos {
            border: solid 1px #eee;
            margin-top: 1em;

            .content {
                padding: 10px;
            }
        }

        .image_infos {
            .content {
                display: flex;
                flex-direction: column;

                .row {
                    padding: 10px;
                    border-bottom: solid 1px #eee;

                    &:last-child {
                        border: none;
                    }

                    label {
                        flex-grow: 1;
                    }
                    .line.right {
                        align-items: center;
                        justify-content: flex-end;
                    }
                }
            }
        }
        .select_days {
            .content {
                display: flex;
                width: 100%;
                flex-direction: row;

                &.col {
                    flex-direction: column;
                }

                span {
                    display: block;
                    text-align: center;
                    margin-bottom: 20px;
                    color: grey;
                    font-size: 16px;
                }
            }
        }

        .input_field {
            margin: 3px 0;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 94%;

            label {
                flex: 0.5;
            }
            input {
                flex: 0.5;
            }

            &.time {
                background-color: #eee;
                padding: 10px 15px;
                width: 100%;
                box-sizing: border-box;

                label {
                    flex-grow: 1;
                }
                input {
                    width: 30px;
                    border: none;
                }
            }
        }

        input {
            padding: 3px;
        }

        input[type="number"] {
            text-align: right;
            width: 50px !important;
            margin-right: 10px;
        }

        input.title {
            width: 100%;
            padding: 15px;
            font-size: 16px;
            margin: 20px 0;
            border: solid 1px #eee;
            border-radius: 4px;
            box-sizing: border-box;

            &:hover {
                border-color: rgb(126, 126, 126);
            }

            &:focus {
                outline: none;
                border-color: $primary;
            }
        }

        .screens {
            background-color: #f6f6f6;
            padding: 0.5em;
            border-radius: 0.5em;

            .row {
                flex-wrap: wrap;
            }

            .item {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .label {
                    display: block;
                    width: 50%;
                }
            }

            strong{
                font-size: 1.2em;
                color:#3a3a3a;
                margin-bottom:1em;
            }
        }

        .media_info {
            margin: 10px 0;
            border-bottom: solid 1px #eee;
            padding: 10px;
            align-items: center;

            &.warn_message {
                background-color: rgba($primary, 0.1);
                border-radius: 0.5em;
            }

            &.no_border {
                border: none;
            }

            span.label {
                flex-grow: 1;
            }
        }

        .tab_selector {
            width: 100%;
        }

        .tab_content {
            padding: 10px 0;
            box-sizing: border-box;
            height: 100%;

            position: relative;
            .tab {
                padding: 1em 0;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transform: translateX(-10%);
                transition: 0.2s;

                &.active {
                    opacity: 1;
                    visibility: visible;
                    transform: translateX(0);
                }
            }
        }
    }

    .details_params {
        display: flex;
        flex-direction: column;
    }

    &.modalMedia {
        img {
            background-color: black;
            width: 55%;
            height: 650px;
            max-height: 100%;
            object-fit: contain;
        }
    }
    .actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5em;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1em;
        box-sizing: border-box;

        .saveButton {
            margin-left: 10px;
        }
    }
}

// SHOW

.show {
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: #000;
    img {
        width: 100vw;
        height: 100vh;
        object-fit: contain;
    }

    .session,
    .picture {
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        visibility: hidden;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    .session {
        box-sizing: border-box;
        position: relative;
        width: 100vw;
        height: 100vh;
        .content {
            width: 100%;
            height: 85vh;
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 3em;
            box-sizing: border-box;
        }

        .progra {
            height: 15vh;
            background-color: #fff;

            box-sizing: border-box;
            width: 100%;
            position: relative;

            .bottom {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 0;
                object-fit: fill;
            }

            .content {
                padding: 1em 3em;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &.col {
                    flex-direction: column;
                }
                span,
                strong {
                    font-size: 35px;

                    @media all and (max-width: 1450px) {
                        font-size: 30px;
                    }
                }

                strong {
                    margin: 0 0.4em;
                    font-weight: 900;
                }
                .grignoux {
                    width: 400px;
                    height: 160px;
                    margin-left: 60px;
                    position: relative;
                    top: -35px;

                    @media all and (max-width: 1450px) {
                        width: 300px;
                        height: 140px;
                        top: -25px;
                    }
                }
            }
        }
        .head {
            position: relative;
            z-index: 2;
            flex: 0.3;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                font-size: 30px;
                font-weight: 800;
                display: flex;
                flex-direction: column;
                text-transform: uppercase;

                &.color1 {
                    color: $parc !important;
                }
                &.color2 {
                    color: $churchill !important;
                }
                &.color3 {
                    color: $sauveniere !important;
                }
                &.color4 {
                    color: $cameo !important;
                }
            }

            .date {
                font-size: 30px;
                color: #fff;
                text-transform: lowercase;
                display: flex;
                flex-direction: column;

                span {
                    font-size: 30px;
                    font-weight: 300;
                }
                &.color1 {
                    color: $parc;
                }

                &.color2 {
                    color: $churchill;
                }
                &.color3 {
                    color: $sauveniere;
                }
                &.color4 {
                    color: $cameo;
                }
            }
        }

        .session_content {
            position: relative;
            z-index: 2;
            list-style: none;
            padding: 0;
            flex: 0.7;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 10vw;

            li {
                display: flex;
                border-bottom: dashed 1px #929292;
                padding: 20px 10px;
                align-items: center;

                &:last-child {
                    border-bottom: none;
                }

                .hour {
                    width: 10%;
                    color: #fff;
                    font-size: 30px;
                    font-weight: 300;
                }

                .title {
                    width: 80%;
                    color: #fff;
                    font-size: 35px;
                    font-weight: 900;
                }
                .room {
                    width: 15%;
                    color: #fff;
                    font-size: 30px;
                    text-align: right;
                    font-weight: 300;
                }
            }
        }

        .respect {
            z-index: 1;
            position: absolute;
            bottom: 30px;
            display: inline-flex;
            padding: 10px 10px;
            background-color: #fff;
            align-items: center;
            justify-content: center;
            color: grey;
            width: 60vw;
            left: 20vw;
            border-radius: 100px;

            span {
                margin: 0 30px;
                flex: 0.9;
                color: red;
                font-size: 1.5em;
                font-weight: 600;
            }
            svg {
                transform: scale(1.4);
                margin: 10px;

                &.info {
                    flex: 0.1;
                    color: grey;
                }
                &.no_icon {
                    flex: 0.05;
                    color: red;
                }
            }
        }

        .place_icon {
            width: 360px;
            margin-bottom: 20px;
        }
    }
}

.add_screen_modal,
.delete_screen_modal {
    .head {
        padding: 30px;
        background-color: $primary;
        color: #fff;
        position: relative;

        .head_icon {
            position: absolute;
            right: 10px;
            font-size: 4em;
            top: 10px;
            opacity: 0.1;
        }
    }

    .modalContent {
        width: 600px;
        max-width: 90vw;

        .modal_input {
            width: 100%;
            margin: 8px 0;
        }
    }
}
.delete_screen_modal .head {
    background-color: red;
}

.screen_status {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
        flex: 0.2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .bullet_status {
            display: block;
            width: 10px;
            height: 10px;
            background: grey;
            border-radius: 10px;
            position: relative;
            top: 7px;
            left: -7px;

            &.active {
                background: rgb(97, 201, 97);
            }

            &.offline {
                background: rgb(255, 91, 91);
            }

            &.only_pictures,
            &.only_sessions {
                background: rgb(255, 201, 114);
            }
        }
    }
    .select {
        flex: 0.8;
    }
}

.offline {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    img {
        width: 300px;
        height: auto;
    }

    &.loading {
        img {
            opacity: 0.3;
            transform: scale(1);
            animation: pulse 4s infinite;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        opacity: 0.3;
    }

    50% {
        transform: scale(1);
        opacity: 0.6;
    }

    100% {
        transform: scale(0.95);
        opacity: 0.3;
    }
}

// tabs

.screen_pictures {
    height: 100%;
    display: flex;
    flex-direction: column;

    .col {
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 8px 15px -9px rgba(0, 0, 0, 0.1);
        height: 100%;
    }

    .tabs_selector {
        border-bottom: solid 1px #eee;
        margin-bottom: 1em;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background-color: #fff;
        padding-top: 10px;

        .tab_selector {
            background-color: transparent;
            border: none;
            transition: 0.1s;
            border-bottom: solid 2px transparent;
            padding: 5px 10px;
            font-size: 1em;
            text-transform: uppercase;
            cursor: pointer;
            &.active {
                color: $primary;
                border-color: $primary;
            }
        }
    }

    .tabs_content {
        position: relative;
        width: 100%;
        height: 100%;

        .tab_content {
            position: absolute;
            transition: 0.3s;
            opacity: 0;
            width: 100%;
            height: 100%;
            visibility: hidden;
            transform: translateX(1em);
            overflow: auto;

            &.active {
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
            }
        }
    }
}

.searchInput {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    margin: 20px 0;
    border: solid 1px #eee;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: #aaa;
    }
}

.linkedScreens {
    list-style: none;
    padding: 10px 0;

    li {
        padding: 10px 5px;
        border-bottom: solid 1px #eee;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            border: none;
        }

        span {
            width: 75%;
        }
        a {
            width: 20%;
            text-decoration: none;
            color: red;
            text-align: right;
        }

        button {
            padding: 10px;
            border: none;
            background-color: $primary;
            border-radius: 4px;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                background-color: darken($primary, 10%);
            }
        }

        &.select {
            .selector {
                width: 70%;

                > div {
                    width: 100%;
                }
            }

            button {
                width: 20%;
            }
        }
    }
}
