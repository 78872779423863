#form-file-upload {
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 10em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;

    transition:.3s;

    &:hover{
        border-color: $primary;
    }
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button {
    background-color:$primary;
    color:#FFF;
    border-radius: 1rem;
    cursor: pointer;
    padding: 5px 15px;
    font-size:14px;
    border: none;
    text-decoration: none !important;
    text-transform: uppercase;

    &:hover{
       transform:scale(1.02);
    }
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .uploadGallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 1rem;
    margin-bottom: 1rem;

    img{
      margin:3px;
      width:75px;
      height: 75px;
      object-fit: cover;
    }
  }