.header {
    background-color: #fff;
    width: 100%;
    padding: 2em;
    background-color: red;
    height: 10vh;
}
.searchField {
    width:60%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .searchFieldInput{
        width: 100%;

        input{
            padding-right:21em;

        }
    }

    .searchKeyInfo{
        color:$midGrey;
        width:25em;
        display: flex;
        align-items: center;
        font-size: .9em;
        position: absolute;
        right:1em;
        transition:.3s;

        &.empty{
            opacity: 0;
        }
    
        svg{
            margin: 0 .3em;
            background-color: $lightGrey;
            padding:0 .5em;
            font-size: 1.2em;
        }
    }
 
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained).MuiButton-outlined.logout {
    color: $primary !important;
    border-color: $primary !important;
    border: none !important;
}
