.MuiDataGrid-root {
    height:78% !important;
    background: #FFF !important;
    border-radius: .6em !important;

    @media all and (max-width : 1450px){
        height:75% !important;
    }
}

.MuiDataGrid-footerContainer{
    display: flex;
    flex-direction: row-reverse;
}