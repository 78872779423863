$colorScheme1: #008683;
$colorScheme2: #322c31;
$colorScheme3: #575267;
$colorScheme4: #fccd87;
$colorScheme5: #99201c;
$colorScheme6: #fff7e9;
$colorScheme7: #7bb9b3;
$colorScheme8: #a9ce7d;


$darkGrey: rgb(72, 72, 72);
$lightGrey: rgb(247, 247, 247);
$midGrey: #9b9b9b;

$primary: $colorScheme1;
$secondary: $colorScheme2;
$third: $colorScheme3;

$background: #fff;

$dark: $colorScheme2;
$alert: $colorScheme5;
$valid: $colorScheme8;
$error: $alert;
$success: $valid;

$low: $colorScheme8;
$mid: $colorScheme4;
$high: $colorScheme1;

$assigned: $colorScheme7;
$start: $colorScheme3;
$review: $colorScheme4;
$done: $colorScheme8;

// CSS VARIABLES

:root {
    --primary: $primary;
    --secondary: $secondary;
    --third: $third;
}

// socials

$facebook: #3b5998;
$twitter: #1da1f2;
$google: #db4437;


// cinemas

$parc : #ec3a15;
$sauveniere : #a4c927;
$churchill : #49a6c6;
$cameo : #f2a823;
