@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");
:root {
  --primary: $primary;
  --secondary: $secondary;
  --third: $third;
}

.header {
  background-color: #fff;
  width: 100%;
  padding: 2em;
  background-color: red;
  height: 10vh;
}

.searchField {
  width: 60%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.searchField .searchFieldInput {
  width: 100%;
}
.searchField .searchFieldInput input {
  padding-right: 21em;
}
.searchField .searchKeyInfo {
  color: #9b9b9b;
  width: 25em;
  display: flex;
  align-items: center;
  font-size: 0.9em;
  position: absolute;
  right: 1em;
  transition: 0.3s;
}
.searchField .searchKeyInfo.empty {
  opacity: 0;
}
.searchField .searchKeyInfo svg {
  margin: 0 0.3em;
  background-color: rgb(247, 247, 247);
  padding: 0 0.5em;
  font-size: 1.2em;
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained).MuiButton-outlined.logout {
  color: #008683 !important;
  border-color: #008683 !important;
  border: none !important;
}

.navigation {
  flex: 0.1;
  width: 10vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding-bottom: 2em;
  background-color: rgb(33, 33, 33);
  padding: 2em 0;
  position: relative;
}
@media all and (max-width: 1450px) {
  .navigation {
    flex: 0.15;
    width: 15vw;
  }
}
.navigation .logo {
  width: 80%;
  height: auto;
}
.navigation .menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4em;
}
.navigation .menu a {
  padding: 1em;
  border: none;
  background-color: transparent;
  color: #9b9b9b;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.2px;
  transition: 0.3s;
}
.navigation .menu a.subMenu {
  padding: 0 1em;
}
.navigation .menu a.subMenu svg {
  opacity: 0;
}
.navigation .menu a:hover {
  color: #fff;
}
.navigation .menu a img {
  width: 50px;
  height: 50px;
  transition: 0.3s;
}
.navigation .menu a svg {
  color: #9b9b9b;
  transition: 0.3s;
  font-size: 1.6em;
  margin-right: 1em;
}
.navigation .menu a:hover svg {
  color: #fff;
}
.navigation .menu a.active {
  color: #fff;
}
.navigation .menu a.active svg {
  color: #008683;
}
@media all and (max-width: 768px) {
  .navigation {
    width: 5em;
  }
  .navigation .userConnected {
    width: 2em;
    height: 2em;
  }
}

.userConnected {
  position: relative;
  width: 45px;
  height: 45px;
  background-color: #9b9b9b;
  border-radius: 3em;
  transition: 0.3s;
}
.userConnected:after {
  position: absolute;
  display: none;
  content: "";
  width: 12px;
  height: 12px;
  background-color: #a9ce7d;
  border-radius: 30px;
  bottom: 0px;
  right: 4px;
}
.userConnected img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 100%;
}
.userConnected:hover {
  transform: scale(1.1);
}

.Page {
  flex: 0.9;
  width: 90vw;
}
.Page header {
  display: flex;
  flex-direction: row;
  height: 10%;
  align-items: center;
  padding-left: 2em;
  box-sizing: border-box;
}
.Page header .pageName {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Page header .pageName .logo {
  width: 6em;
  height: auto;
}
.Page header .pageName .icon {
  width: 2em;
  height: 2em;
  background-color: #322c31;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border-radius: 0.3em;
  margin-right: 1em;
  font-size: 1.2em;
}
.Page header .pageName .icon img {
  width: 3em;
  height: 3em;
  filter: brightness(10);
}
.Page header .pageName strong {
  font-weight: 600;
  font-size: 1.4em;
}
.Page header .notifications {
  flex: 0.05;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}
.Page header .notifications svg {
  transform: scale(1.2);
}
.Page header .notifications span {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #008683;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -8px;
  margin-top: -15px;
  border-radius: 3em;
  font-size: 0.85em;
}
.Page header .notifications span.min {
  font-size: 0.65em;
}
.Page header .searchBar {
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.Page header .searchBar input {
  border: none;
  padding: 1em;
  background-color: #eee;
  border-radius: 0.4em;
  width: 100%;
}
@media all and (max-width: 768px) {
  .Page header {
    padding-left: 0;
  }
  .Page header .searchBar {
    display: none;
  }
  .Page header .pageName {
    flex: 0.9;
  }
}
.Page > .infos {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.Page > .infos .tabs {
  padding: 1em 1em 1em 2em;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  width: 80%;
}
.Page > .infos .tabs li {
  margin-right: 4em;
}
.Page > .infos .tabs li a {
  color: rgb(186, 186, 186);
  text-decoration: none;
}
.Page > .infos .tabs li a span {
  display: inline-flex;
  width: 2em;
  height: 1.3em;
  background: rgb(177, 177, 177);
  border-radius: 0.2em;
  color: #fff;
  font-size: 0.8em;
  padding: 0.2em;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.Page > .infos .tabs li a.active {
  color: rgb(63, 63, 63);
  font-weight: 600;
}
.Page > .infos .tabs li a.active span {
  background: #008683;
}
@media all and (max-width: 991px) {
  .Page > .infos .tabs li {
    display: none;
  }
  .Page > .infos .tabs li:nth-child(1), .Page > .infos .tabs li:nth-child(2), .Page > .infos .tabs li:nth-child(3), .Page > .infos .tabs li:nth-child(4) {
    display: inline;
  }
}
@media all and (max-width: 991px) {
  .Page > .infos .tabs li {
    margin-right: 3em;
  }
  .Page > .infos .tabs li:nth-child(4) {
    display: none;
  }
}
.Page > .infos .actions {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: flex-end;
}
.Page > .content {
  background-color: rgb(247, 247, 247);
  width: 100%;
  height: 80%;
  border-radius: 0.3em 0.3em 0 0;
  padding: 2em;
  box-sizing: border-box;
  position: relative;
  overflow-y: auto;
}
.Page > .content.fancyboxContainer > div {
  height: 100%;
  display: flex;
}
.Page > .content .tabContent h3 {
  margin: 0 0 1em 0;
  font-weight: 400;
  color: rgb(59, 59, 59);
  font-size: 1.5em;
}
.Page.dashboard > .content, .Page.reports > .content, .Page.settings > .content {
  height: 90%;
}
.Page.listing > .content {
  height: 90%;
  overflow: hidden;
}
.Page.listing > .content > .row {
  height: 100%;
}

.cgu {
  display: flex;
  flex-direction: column;
  padding: 4em;
  text-align: center;
  width: 75vw;
  margin: auto;
}
.cgu strong {
  font-size: 2em;
}

.LightBox {
  position: fixed;
  transition: 0.3s;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
}
.LightBox.open {
  opacity: 1;
  visibility: visible;
}
.LightBox.close {
  opacity: 0;
  visibility: hidden;
}
.LightBox .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: -1;
}
.LightBox .close {
  position: fixed;
  right: 2em;
  top: 2em;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.4em;
}
.LightBox .close img {
  width: 1.5em;
}
.LightBox .nav_button {
  position: fixed;
  top: 45vh;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  border: none;
  color: #000;
  font-size: 1.4em;
  transition: 0.3s;
  background-color: transparent;
  opacity: 0.4;
  cursor: pointer;
}
.LightBox .nav_button img {
  width: 2.5em;
}
.LightBox .nav_button:hover {
  opacity: 1;
}
.LightBox .nav_button.previous {
  left: 2vw;
}
.LightBox .nav_button.next {
  right: 2vw;
}
.LightBox .caption {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2em;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9em;
}
.LightBox .pictureContainer {
  overflow: none;
  max-width: 70vw;
  max-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LightBox .pictureContainer img {
  height: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  transition: 0.3s;
}
.LightBox .pictureContainer img:hover {
  transform: scale(1.005);
}
.LightBox .pictureContainer.zoom {
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
}
.LightBox .pictureContainer.zoom img {
  width: auto;
  height: 100%;
  max-width: none;
  max-height: none;
}
.LightBox .pictureContainer.zoom img:hover {
  transition: 0s;
  transform: scale(1);
}
.LightBox img {
  max-width: 70vw;
  max-height: 80vh;
  transition: 0.1s;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.gallery img {
  width: 7em;
  height: 5em;
  border-radius: 0.3em;
  cursor: pointer;
  margin: 0.2em;
  -o-object-fit: cover;
     object-fit: cover;
  will-change: transform;
}

.greeting {
  padding-bottom: 2em;
}
.greeting .row {
  align-items: center;
  font-size: 1.1em;
}
.greeting .row img {
  margin-right: 0.4em;
  width: 3.5em;
  height: 3.5em;
  border-radius: 4em;
}
.greeting .row .col strong {
  color: rgb(72, 72, 72);
}
.greeting .row .col .name {
  align-items: flex-end;
}
.greeting .row .col .name span {
  color: #9b9b9b;
  font-size: 1.3em;
}
.greeting .row .col .name a {
  position: relative;
  bottom: -3px;
  margin-left: 1em;
  color: #9b9b9b;
  transition: 0.3s;
}
.greeting .row .col .name a svg {
  transform: scale(0.8);
}
.greeting .row .col .name a:hover {
  color: rgb(72, 72, 72);
  transform: rotate(45deg);
}

.module {
  background-color: #fff;
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
  padding: 1em;
  border-radius: 0.4em;
}
.module .module_header {
  border-bottom: solid 1px rgb(222, 222, 222);
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.3em 0;
}
.module .module_header strong {
  width: 60%;
  font-weight: 400;
  color: rgb(80, 80, 80);
  flex-grow: 1;
}
.module .module_header span {
  text-align: right;
  width: 8%;
  display: none;
  color: rgb(190, 190, 190);
  font-size: 0.8em;
}
.module .module_header .add_project_button {
  font-size: 0.8em;
}
.module .module_header .add_project_button svg {
  font-size: 1.6em;
  position: relative;
  left: -0.2em;
  top: -0.15em;
}
.module .module_content .item {
  border-bottom: solid 1px rgb(232, 230, 230);
  padding: 0.4em 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.module .module_content .item .thumb {
  width: 5%;
  max-width: 2em;
  height: 2em;
  background-color: #fccd87;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  border-radius: 0.5em;
  margin-right: 1em;
}
.module .module_content .item .name {
  text-decoration: none;
  width: 70%;
  color: rgb(46, 46, 46);
  font-weight: 500;
}
.module .module_content .item .actions {
  width: 25%;
}
.module .module_content .item .actions button {
  justify-self: flex-end;
  float: right;
  border: solid 2px #575267;
  color: #575267;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
  padding: 0.3em 1em;
  border-radius: 0.3em;
  font-weight: 500;
}
.module .module_content .item .actions button:hover {
  background-color: #575267;
  color: #fff;
}
.module .module_content .item .actions button.delete {
  color: #99201c;
}

.start_project {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.start_project img {
  width: 4em;
  height: 4em;
}
.start_project strong {
  font-size: 2em;
  color: #9b9b9b;
  padding: 1em 0;
}

.tableTitle {
  display: flex;
}
.tableTitle img {
  width: 80px;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
  background-color: rgb(247, 247, 247);
  margin-right: 1em;
}
.tableTitle .movieInfos {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.tableTitle .movieInfos .movieTitle {
  font-size: 1.3em;
  color: rgb(72, 72, 72);
}
.tableTitle .movieInfos .movieTitleOriginal {
  font-size: 1em;
  color: #9b9b9b;
}
.tableTitle .movieInfos .producer {
  margin-top: 0.2em;
  cursor: pointer;
}
.tableTitle .movieInfos .actors {
  font-style: italic;
  font-size: 0.9em;
  color: #9b9b9b;
}

.user .pageName {
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  position: relative;
}
.user .pageName .goBack {
  background-color: transparent;
  color: #008683;
  border: none;
  display: flex;
  align-items: center;
  margin: 1em 3em 0.5em 0;
  cursor: pointer;
}
.user .pageName .goBack svg {
  margin-right: 0.3em;
}
.user .pageName .saveButton {
  position: absolute;
  right: 0;
  top: 2em;
}

.userHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userHeader .userPicture {
  width: 3em;
  height: 3em;
  border-radius: 4em;
  margin-right: 1em;
  background-color: rgb(247, 247, 247);
}

.userInfos .card .title {
  display: block;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border-bottom: solid 1px rgb(214, 214, 214);
  font-size: 1.2em;
  color: #008683;
  position: relative;
}
.userInfos .card .title .saveHeaderButton {
  position: absolute;
  right: 1em;
  bottom: 0.5em;
}
.userInfos .card .input {
  width: 100%;
  margin: 0.5em 0;
}

/** dialog confirm */
.dialog {
  padding: 2em;
  text-align: center;
  width: 400px;
  max-width: 90%;
}
.dialog .actions {
  margin-top: 1em;
  padding-top: 1em;
  border-top: solid 1px rgb(207, 207, 207);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

/** scan */
.scan {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 2em;
  background-color: #fff;
  text-align: center;
}
.scan svg {
  transform: scale(2);
  margin-bottom: 0.5em;
  color: rgb(183, 183, 183);
}
.scan .separator {
  font-size: 1.2em;
  margin: 1em 0;
  display: block;
  text-align: center;
}
.scan .MuiTextField-root {
  margin: 1em 0;
  width: 100%;
}

.modalHeader {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 92%;
  background-color: #008683;
}
.modalHeader span {
  font-size: 0.7em;
  font-weight: 300;
}
.modalHeader span.title {
  font-size: 20px;
  font-weight: 500;
}
.modalHeader.error {
  background-color: rgb(255, 96, 96);
}
.modalHeader.success {
  background-color: rgb(114, 181, 75);
}

.modalContent {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalContent.form {
  min-width: 50vw;
}
.modalContent .info {
  color: #6e6e6e;
  font-style: italic;
  display: block;
  margin: 10px 0;
}
.modalContent .message {
  padding: 0.5em;
  background-color: #e4e4e4;
  text-align: center;
  border-radius: 4px;
  margin: 10px 0;
  width: 90%;
}
.modalContent .message.error {
  background-color: rgb(255, 208, 208);
  color: rgb(255, 96, 96);
}
.modalContent .message.error.list {
  text-align: left;
  max-height: 300px;
  overflow-y: auto;
}
.modalContent .message.success {
  background-color: rgb(233, 255, 220);
  color: rgb(86, 145, 52);
}
.modalContent .modalItemCol {
  display: flex;
  flex-direction: column;
}
.modalContent .validCode {
  font-size: 20px;
  display: block;
  font-weight: bold;
  color: rgb(114, 181, 75);
  margin-top: 1em;
}
.modalContent .errorCode {
  font-size: 20px;
  display: block;
  font-weight: bold;
  color: rgb(255, 96, 96);
  margin-top: 1em;
}
.modalContent .errorIndicator {
  display: block;
  text-align: center;
  color: grey;
  margin-top: 0.4em;
  font-size: 0.9em;
}
.modalContent .closeButton {
  margin: 2em 0;
}

.link_screen {
  color: #008683;
  margin-right: 1em;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
}
.link_screen:hover {
  color: grey;
}

.screens_file_list {
  width: 100%;
  list-style: none;
  padding: 0;
  border-radius: 3px;
  border: solid 1px rgb(232, 232, 232);
  height: 100%;
  overflow-y: auto;
}
.screens_file_list .item-container:nth-child(2n) {
  background-color: #ffffff;
}
.screens_file_list li {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
}
.screens_file_list li > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}
.screens_file_list li > div span.position {
  flex: 0.1;
  font-size: 1.3em;
  width: 40px;
  text-align: center;
}
.screens_file_list li > div img {
  flex: 0.2;
  max-width: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 40px;
}
.screens_file_list li > div .picture_name {
  flex: 0.6;
}
.screens_file_list li > div .action {
  flex: 0.2;
  text-align: right;
  float: right;
}
.screens_file_list li > div .action button {
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
}
.screens_file_list li > div .action button.edit_button {
  color: #008683;
  font-size: 14px;
}

.image_modal .MuiDialog-paper {
  width: 1600px;
  max-width: 90vw;
}

.warning_modal {
  z-index: 9999999999;
}

.image_details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  position: relative;
  height: 650px;
  max-height: 95vh;
}
.image_details img {
  position: relative;
  width: 45%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.image_details .info {
  width: 55%;
  box-sizing: border-box;
  padding: 1em;
  height: 100% !important;
  position: relative;
  overflow: hidden;
  padding-bottom: 5em;
  margin: 0;
  font-style: normal;
}
.image_details .info .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
}
.image_details .info .header .title {
  flex: 0.8;
}
.image_details .info .title {
  text-wrap: wrap;
  font-size: 1.3em;
  height: 30px;
}
.image_details .info .hide_details {
  display: block;
  position: absolute;
  top: 25%;
  height: 90%;
  width: 100%;
  left: 0;
  background-color: #fff;
  opacity: 0.5;
  z-index: 44;
}
.image_details .info .switch_field {
  padding: 0.3em 1em;
  background-color: #f2f2f2;
  text-align: right;
  border-radius: 3px;
}
.image_details .info .days_details {
  margin-top: 2px;
  padding: 0.3em 1em;
  background-color: #eee;
}
.image_details .info .hours_details,
.image_details .info .image_infos {
  border: solid 1px #eee;
  margin-top: 1em;
}
.image_details .info .hours_details .content,
.image_details .info .image_infos .content {
  padding: 10px;
}
.image_details .info .image_infos .content {
  display: flex;
  flex-direction: column;
}
.image_details .info .image_infos .content .row {
  padding: 10px;
  border-bottom: solid 1px #eee;
}
.image_details .info .image_infos .content .row:last-child {
  border: none;
}
.image_details .info .image_infos .content .row label {
  flex-grow: 1;
}
.image_details .info .image_infos .content .row .line.right {
  align-items: center;
  justify-content: flex-end;
}
.image_details .info .select_days .content {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.image_details .info .select_days .content.col {
  flex-direction: column;
}
.image_details .info .select_days .content span {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  color: grey;
  font-size: 16px;
}
.image_details .info .input_field {
  margin: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 94%;
}
.image_details .info .input_field label {
  flex: 0.5;
}
.image_details .info .input_field input {
  flex: 0.5;
}
.image_details .info .input_field.time {
  background-color: #eee;
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
}
.image_details .info .input_field.time label {
  flex-grow: 1;
}
.image_details .info .input_field.time input {
  width: 30px;
  border: none;
}
.image_details .info input {
  padding: 3px;
}
.image_details .info input[type=number] {
  text-align: right;
  width: 50px !important;
  margin-right: 10px;
}
.image_details .info input.title {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  margin: 20px 0;
  border: solid 1px #eee;
  border-radius: 4px;
  box-sizing: border-box;
}
.image_details .info input.title:hover {
  border-color: rgb(126, 126, 126);
}
.image_details .info input.title:focus {
  outline: none;
  border-color: #008683;
}
.image_details .info .screens {
  background-color: #f6f6f6;
  padding: 0.5em;
  border-radius: 0.5em;
}
.image_details .info .screens .row {
  flex-wrap: wrap;
}
.image_details .info .screens .item {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.image_details .info .screens .item .label {
  display: block;
  width: 50%;
}
.image_details .info .screens strong {
  font-size: 1.2em;
  color: #3a3a3a;
  margin-bottom: 1em;
}
.image_details .info .media_info {
  margin: 10px 0;
  border-bottom: solid 1px #eee;
  padding: 10px;
  align-items: center;
}
.image_details .info .media_info.warn_message {
  background-color: rgba(0, 134, 131, 0.1);
  border-radius: 0.5em;
}
.image_details .info .media_info.no_border {
  border: none;
}
.image_details .info .media_info span.label {
  flex-grow: 1;
}
.image_details .info .tab_selector {
  width: 100%;
}
.image_details .info .tab_content {
  padding: 10px 0;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}
.image_details .info .tab_content .tab {
  padding: 1em 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-10%);
  transition: 0.2s;
}
.image_details .info .tab_content .tab.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}
.image_details .details_params {
  display: flex;
  flex-direction: column;
}
.image_details.modalMedia img {
  background-color: black;
  width: 55%;
  height: 650px;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.image_details .actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1em;
  box-sizing: border-box;
}
.image_details .actions .saveButton {
  margin-left: 10px;
}

.show {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: #000;
}
.show img {
  width: 100vw;
  height: 100vh;
  -o-object-fit: contain;
     object-fit: contain;
}
.show .session,
.show .picture {
  transition: 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
}
.show .session.show,
.show .picture.show {
  opacity: 1;
  visibility: visible;
}
.show .session {
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 100vh;
}
.show .session .content {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 3em;
  box-sizing: border-box;
}
.show .session .progra {
  height: 15vh;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.show .session .progra .bottom {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  -o-object-fit: fill;
     object-fit: fill;
}
.show .session .progra .content {
  padding: 1em 3em;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.show .session .progra .content.col {
  flex-direction: column;
}
.show .session .progra .content span,
.show .session .progra .content strong {
  font-size: 35px;
}
@media all and (max-width: 1450px) {
  .show .session .progra .content span,
  .show .session .progra .content strong {
    font-size: 30px;
  }
}
.show .session .progra .content strong {
  margin: 0 0.4em;
  font-weight: 900;
}
.show .session .progra .content .grignoux {
  width: 400px;
  height: 160px;
  margin-left: 60px;
  position: relative;
  top: -35px;
}
@media all and (max-width: 1450px) {
  .show .session .progra .content .grignoux {
    width: 300px;
    height: 140px;
    top: -25px;
  }
}
.show .session .head {
  position: relative;
  z-index: 2;
  flex: 0.3;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.show .session .head .title {
  font-size: 30px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}
.show .session .head .title.color1 {
  color: #ec3a15 !important;
}
.show .session .head .title.color2 {
  color: #49a6c6 !important;
}
.show .session .head .title.color3 {
  color: #a4c927 !important;
}
.show .session .head .title.color4 {
  color: #f2a823 !important;
}
.show .session .head .date {
  font-size: 30px;
  color: #fff;
  text-transform: lowercase;
  display: flex;
  flex-direction: column;
}
.show .session .head .date span {
  font-size: 30px;
  font-weight: 300;
}
.show .session .head .date.color1 {
  color: #ec3a15;
}
.show .session .head .date.color2 {
  color: #49a6c6;
}
.show .session .head .date.color3 {
  color: #a4c927;
}
.show .session .head .date.color4 {
  color: #f2a823;
}
.show .session .session_content {
  position: relative;
  z-index: 2;
  list-style: none;
  padding: 0;
  flex: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10vw;
}
.show .session .session_content li {
  display: flex;
  border-bottom: dashed 1px #929292;
  padding: 20px 10px;
  align-items: center;
}
.show .session .session_content li:last-child {
  border-bottom: none;
}
.show .session .session_content li .hour {
  width: 10%;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
}
.show .session .session_content li .title {
  width: 80%;
  color: #fff;
  font-size: 35px;
  font-weight: 900;
}
.show .session .session_content li .room {
  width: 15%;
  color: #fff;
  font-size: 30px;
  text-align: right;
  font-weight: 300;
}
.show .session .respect {
  z-index: 1;
  position: absolute;
  bottom: 30px;
  display: inline-flex;
  padding: 10px 10px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  color: grey;
  width: 60vw;
  left: 20vw;
  border-radius: 100px;
}
.show .session .respect span {
  margin: 0 30px;
  flex: 0.9;
  color: red;
  font-size: 1.5em;
  font-weight: 600;
}
.show .session .respect svg {
  transform: scale(1.4);
  margin: 10px;
}
.show .session .respect svg.info {
  flex: 0.1;
  color: grey;
}
.show .session .respect svg.no_icon {
  flex: 0.05;
  color: red;
}
.show .session .place_icon {
  width: 360px;
  margin-bottom: 20px;
}

.add_screen_modal .head,
.delete_screen_modal .head {
  padding: 30px;
  background-color: #008683;
  color: #fff;
  position: relative;
}
.add_screen_modal .head .head_icon,
.delete_screen_modal .head .head_icon {
  position: absolute;
  right: 10px;
  font-size: 4em;
  top: 10px;
  opacity: 0.1;
}
.add_screen_modal .modalContent,
.delete_screen_modal .modalContent {
  width: 600px;
  max-width: 90vw;
}
.add_screen_modal .modalContent .modal_input,
.delete_screen_modal .modalContent .modal_input {
  width: 100%;
  margin: 8px 0;
}

.delete_screen_modal .head {
  background-color: red;
}

.screen_status {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.screen_status .icon {
  flex: 0.2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.screen_status .icon .bullet_status {
  display: block;
  width: 10px;
  height: 10px;
  background: grey;
  border-radius: 10px;
  position: relative;
  top: 7px;
  left: -7px;
}
.screen_status .icon .bullet_status.active {
  background: rgb(97, 201, 97);
}
.screen_status .icon .bullet_status.offline {
  background: rgb(255, 91, 91);
}
.screen_status .icon .bullet_status.only_pictures, .screen_status .icon .bullet_status.only_sessions {
  background: rgb(255, 201, 114);
}
.screen_status .select {
  flex: 0.8;
}

.offline {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.offline img {
  width: 300px;
  height: auto;
}
.offline.loading img {
  opacity: 0.3;
  transform: scale(1);
  animation: pulse 4s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.3;
  }
}
.screen_pictures {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.screen_pictures .col {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 8px 15px -9px rgba(0, 0, 0, 0.1);
  height: 100%;
}
.screen_pictures .tabs_selector {
  border-bottom: solid 1px #eee;
  margin-bottom: 1em;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fff;
  padding-top: 10px;
}
.screen_pictures .tabs_selector .tab_selector {
  background-color: transparent;
  border: none;
  transition: 0.1s;
  border-bottom: solid 2px transparent;
  padding: 5px 10px;
  font-size: 1em;
  text-transform: uppercase;
  cursor: pointer;
}
.screen_pictures .tabs_selector .tab_selector.active {
  color: #008683;
  border-color: #008683;
}
.screen_pictures .tabs_content {
  position: relative;
  width: 100%;
  height: 100%;
}
.screen_pictures .tabs_content .tab_content {
  position: absolute;
  transition: 0.3s;
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  transform: translateX(1em);
  overflow: auto;
}
.screen_pictures .tabs_content .tab_content.active {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

.searchInput {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  margin: 20px 0;
  border: solid 1px #eee;
  border-radius: 4px;
  box-sizing: border-box;
}
.searchInput:focus {
  outline: none;
  border-color: #aaa;
}

.linkedScreens {
  list-style: none;
  padding: 10px 0;
}
.linkedScreens li {
  padding: 10px 5px;
  border-bottom: solid 1px #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.linkedScreens li:last-child {
  border: none;
}
.linkedScreens li span {
  width: 75%;
}
.linkedScreens li a {
  width: 20%;
  text-decoration: none;
  color: red;
  text-align: right;
}
.linkedScreens li button {
  padding: 10px;
  border: none;
  background-color: #008683;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
}
.linkedScreens li button:hover {
  background-color: #005351;
}
.linkedScreens li.select .selector {
  width: 70%;
}
.linkedScreens li.select .selector > div {
  width: 100%;
}
.linkedScreens li.select button {
  width: 20%;
}

.login_container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}
.login_container .form {
  width: 30%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_container .form .content {
  width: 70%;
  min-height: 50%;
  padding: 2em;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
}
.login_container .form .content .switch_account_action {
  text-align: center;
  margin-bottom: 2em;
}
.login_container .form .content .switch_account_action strong {
  display: block;
  font-size: 1.7em;
  margin-bottom: 0.3em;
  font-weight: 600;
  color: rgb(37, 37, 37);
}
.login_container .form .content .switch_account_action span,
.login_container .form .content .switch_account_action a {
  font-size: 0.9em;
}
.login_container .form .content .switch_account_action span {
  color: grey;
}
.login_container .form .content .switch_account_action a {
  color: #008683;
  margin-left: 12px;
}
.login_container .form .content .switch_account_action.success svg {
  fill: rgb(79, 191, 31);
  font-size: 5em;
  margin-top: 1em;
  margin-bottom: 0.2em;
}
.login_container .form .content .fields {
  margin: 0.4em 10%;
}
.login_container .form .content .fields > div {
  width: 100%;
}
.login_container .form .content .fields fieldset {
  border-color: rgb(238, 238, 238);
  border-width: 3px;
}
.login_container .form .content .actions {
  padding: 0em 0 2em 0;
}
.login_container .form .content .actions a {
  color: rgb(87, 82, 103);
  font-size: 0.9em;
  float: right;
  text-align: left;
  transition: 0.3s;
}
.login_container .form .content .actions a:hover {
  color: #008683;
}
.login_container .form .content .actions button {
  margin-top: 1em;
}
.login_container .form .content .actions button svg {
  color: #fff;
  transform: scale(0.4);
}
.login_container .form .content .actions button.Mui-disabled svg {
  color: #322c31;
}
.login_container .form .content .actions .error {
  margin-top: 1em;
}
.login_container .form .content .connect_with {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(119, 119, 119);
  font-size: 0.9em;
  font-weight: 500;
}
.login_container .form .content .connect_with::after, .login_container .form .content .connect_with::before {
  content: "";
  display: block;
  width: 4em;
  height: 2px;
  background-color: rgb(218, 218, 218);
  margin: 1em;
}
.login_container .form .content .oauth_connect {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  transition: 0.3s;
}
.login_container .form .content .oauth_connect span {
  color: #9b9b9b;
  margin-right: 0.5em;
}
.login_container .form .content .oauth_connect .login_button {
  cursor: pointer;
  margin: 0 0.5em;
}
.login_container .form .content .oauth_connect .login_button.google {
  color: #db4437;
}
.login_container .form .content .oauth_connect .login_button.facebook {
  color: #3b5998;
}
.login_container .form .content .oauth_connect .login_button svg {
  transition: 0.3s;
  transform: scale(1.4);
}
.login_container .form .content .oauth_connect .login_button:hover svg {
  transform: scale(1.5);
}
.login_container .presentation {
  width: 70%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cgu_accept {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}
.cgu_accept span {
  font-size: 1em;
  color: rgb(72, 72, 72);
}
.cgu_accept a {
  font-size: 1em;
  color: #008683;
  margin-left: -0.8em;
}

.subscription .row .title {
  color: rgb(72, 72, 72);
  font-size: 1.6em;
  font-weight: 500;
}
.subscription .row .title span.actual_plan_label {
  margin-left: 0.5em;
  background-color: #008683;
  padding: 0em 1em;
  font-size: 0.8em;
  border-radius: 0.3em;
  color: #fff;
  height: 1.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.subscription .row.row_actual_plan {
  margin-bottom: 0em;
}
.subscription .row.row_selector_title {
  align-items: center;
}
.subscription .row.row_selector_title .title {
  font-size: 2em;
  color: rgb(72, 72, 72);
  flex-grow: 1;
}
.subscription .row.row_selector_title .title span {
  color: #008683;
}
.subscription .row.row_selector_title .toggle button {
  background-color: rgb(244, 244, 244);
  color: #008683 !important;
  border: solid 2px #008683;
  border-radius: 0;
  padding: 0.3em 1em;
}
.subscription .row.row_selector_title .toggle button:first-child {
  border-radius: 2em 0 0 2em;
}
.subscription .row.row_selector_title .toggle button:last-child {
  border-radius: 0 2em 2em 0;
}
.subscription .row.row_selector_title .toggle button.active {
  background-color: #008683 !important;
  color: #fff !important;
}
.subscription .row .plan_selector .plan {
  border-radius: 1em;
  overflow: hidden;
}
.subscription .row .plan_selector .plan span.plan_not_selected,
.subscription .row .plan_selector .plan span.actual_selected {
  display: flex;
  padding: 0.3em;
  height: 2em;
}
.subscription .row .plan_selector .plan span.actual_selected {
  align-items: center;
  justify-content: center;
  background-color: #008683;
  color: #fff;
}
.subscription .row .plan_selector .plan .content {
  padding: 1em;
  text-align: center;
}
.subscription .row .plan_selector .plan .content strong {
  font-weight: 500;
  font-size: 1.3em;
}
.subscription .row .plan_selector .plan .content span.price {
  margin-top: 1em;
  font-size: 1.3em;
  font-weight: bold;
  display: block;
  color: #008683;
}
.subscription .row .plan_selector .plan .content ul.advantages {
  list-style: none;
  padding: 0;
}
.subscription .row .plan_selector .plan .content ul.advantages li {
  padding: 0.5em 0;
  font-size: 0.85em;
  color: rgb(84, 84, 84);
  height: 1.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription .row .plan_selector .plan .content ul.advantages li.reduction {
  color: #008683;
}
.subscription .row .plan_selector .plan .content ul.advantages li span.disabled {
  color: rgb(193, 193, 193);
}
.subscription .row .plan_selector .plan .content .select_button:not(.Mui-disabled) {
  color: #008683 !important;
}

.Page.account header .pageName .avatar {
  margin-top: 1em;
  width: 5em;
  height: 5em;
  border-radius: 3em;
  overflow: hidden;
  background-color: rgb(247, 247, 247);
}
.Page.account header .pageName .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.Page.account header .pageName .infos {
  margin-left: 1em;
  display: flex;
  flex-direction: column;
}
.Page.account header .pageName .infos strong {
  color: #322c31;
  font-weight: 500;
}
.Page.account header .pageName .infos .plan {
  opacity: 0.8;
  margin-top: 0.4em;
  background-color: #008683;
  color: #fff;
  width: 5em;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0.2em;
  border-radius: 0.2em;
  text-align: center;
}

.settings_card {
  box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03) !important;
  margin-bottom: 0.5em;
  overflow: initial !important;
}
.settings_card .MuiCardHeader-content {
  border-bottom: solid 1px #f0f0f0;
}
.settings_card .MuiCardHeader-content span {
  font-size: 1.2em !important;
}
.settings_card strong.title {
  border-bottom: solid 1px rgb(247, 247, 247) !important;
  margin-bottom: 0.4em;
  padding-bottom: 0.2em;
  color: #9b9b9b;
  font-weight: 400;
}
.settings_card .settings_actions {
  padding: 1em !important;
}
.settings_card .settings_actions button {
  margin: 0px 0px 0px auto;
}
.settings_card .delete_content {
  display: flex;
}
.settings_card .delete_content .delete_button {
  color: #008683;
  border: none;
  background: transparent;
  margin: 0 0 0 auto;
  display: inherit;
  cursor: pointer;
  transition: 0.3s;
}
.settings_card .delete_content .delete_button:hover {
  color: #99201c;
}

.lock_provider {
  color: #9b9b9b;
  font-style: italic;
  font-size: 0.8em;
  padding: 0 0.3em;
}

.modal {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9;
  top: 0;
  left: 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-2em);
  opacity: 0;
  visibility: hidden;
}
.modal.open {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.modal .overlay {
  position: absolute;
  background-color: rgba(50, 44, 49, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal .closeModal {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1em;
}
.modal .closeModal svg {
  transform: scale(0.8);
}
.modal .content {
  position: relative;
  width: 1200px;
  max-width: 90%;
  min-height: 40%;
  padding: 2em 2em 5em 2em;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
  border-radius: 0.3em;
}
.modal .content .title span,
.modal .content .title strong {
  display: block;
}
.modal .content .title span {
  color: grey;
}
.modal .content .title strong {
  color: #008683;
  font-size: 2em;
}
.modal .content .row.sep {
  margin: 1em 0;
}
.modal .content .row .col > div,
.modal .content .row .col input {
  width: 100%;
}
.modal .content .add_file_button {
  float: right;
  margin-top: -2em;
}
.modal .content .MuiAlert-root {
  position: absolute;
  bottom: 2em;
  left: 2em;
}
.modal .content .action_button {
  position: absolute;
  bottom: 2em;
  right: 2em;
}
.modal .content .action_button span {
  width: 25px !important;
  height: 25px !important;
}
.modal .content .action_button svg {
  color: #fff;
  transform: scale(0.6);
}
.modal .content textarea {
  width: 100%;
  min-height: 10em;
  max-width: 100%;
  min-width: 100%;
  padding: 1em;
  box-sizing: border-box;
  border: solid 1px rgb(208, 208, 208);
  border-radius: 0.3em;
  max-height: 30em;
}

.modalForm .modalContent,
.modalForm .modalHeader {
  padding: 3em;
  min-width: 600px;
  max-width: 90vw;
  box-sizing: border-box;
}
.modalForm .modalContent {
  max-height: 90vh;
}
.modalForm .modalContent .modal_form .select {
  min-width: 400px;
}
.modalForm .modalContent .modal_form .row {
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.modalForm .modalContent .modal_form .row .col {
  flex: 1;
}
.modalForm .modalContent .modal_form .row span {
  color: #9c9c9c;
}
.modalForm .modalContent .modal_form .row.actions {
  border-top: solid 1px #9c9c9c;
  margin-top: 60px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalDelete {
  padding: 3em;
}
.modalDelete .row {
  margin: 1em 0;
}

.videoModal {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.videoModal::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.editor {
  position: relative;
}
.editor .title {
  position: absolute;
  font-size: 0.9em;
  top: 0.3em;
  color: rgb(154, 154, 154);
}
.editor .menubar {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(0.3em);
  background-color: #fff;
}
.editor .menubar .menu_group {
  position: relative;
  z-index: 1;
  display: inline-block;
  border-left: solid 1px rgb(204, 204, 204);
  box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
}
.editor .menubar .menu_group:first-child {
  border-left: none;
}
.editor .menubar .menu_group button {
  background: transparent;
  border: none;
  margin: 0 0.3em;
  padding: 0 0.2em;
  font-size: 0.7em;
  transition: 0.3s;
  cursor: pointer;
}
.editor .menubar .menu_group button svg {
  transform: scale(0.8);
  transition: 0.3s;
  fill: #202020;
}
.editor .menubar .menu_group button:hover svg {
  fill: #008683;
}
.editor .menubar .menu_group button:disabled {
  cursor: default;
}
.editor .menubar .menu_group button:disabled svg {
  fill: #c1c1c1;
}
.editor .menubar .menu_group button:disabled:hover svg {
  fill: #c1c1c1;
}
.editor .menubar .menu_group button.open_color_nav.active, .editor .menubar .menu_group button.is-active {
  background-color: rgb(233, 233, 233);
}
.editor .menubar .color_nav {
  position: relative;
  z-index: 0;
  transform: translateX(-0.4em);
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}
.editor .menubar .color_nav.open {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
}
.editor .menubar .color_nav button {
  padding: 0 0.3em;
  border: none;
  background: transparent;
  cursor: pointer;
}
.editor .menubar .color_nav button div {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  transition: 0.3s;
}
.editor .menubar .color_nav button:hover div {
  transform: scale(1.1);
}
.editor .menubar.min .color_nav {
  width: 25%;
}
.editor .ProseMirror p.is-editor-empty:first-child:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.editor .ProseMirror {
  padding: 0.3em 1em;
  border: solid 1px rgb(222, 222, 222);
  transition: 0.3s;
  border-radius: 0 0.4em 0.4em;
  outline: none;
  min-height: 6em;
  max-height: 20em;
  overflow: auto;
}
.editor .ProseMirror p,
.editor .ProseMirror strong,
.editor .ProseMirror span {
  color: #373737;
}
.editor .ProseMirror strong {
  font-weight: 500;
  font-size: 1em;
}
.editor .ProseMirror h3 {
  font-weight: 600;
}
.editor .ProseMirror h3 strong {
  font-weight: 600;
}
.editor:focus-visible .menubar, .editor:hover .menubar, .editor:focus-within .menubar {
  opacity: 1;
  transform: translateY(0);
}
.editor:focus-visible .ProseMirror, .editor:hover .ProseMirror, .editor:focus-within .ProseMirror {
  outline: none;
  border-color: #fccd87;
}

.list,
.columns {
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
}
.list .item,
.columns .item {
  width: 100%;
  padding: 1em 2em;
  box-sizing: border-box;
  border-bottom: solid 1px rgb(224, 224, 224);
  display: flex;
  flex-direction: row;
}
.list .item:last-child,
.columns .item:last-child {
  border-bottom: none;
}
.list .item .type,
.columns .item .type {
  width: 5%;
  text-align: center;
}
.list .item .type img,
.columns .item .type img {
  width: 40px;
}
.list .item .content,
.columns .item .content {
  width: 40%;
  padding: 0 0.3em;
  height: 4em;
}
.list .item .date,
.columns .item .date {
  width: 15%;
  text-align: center;
}
.list .item .creator,
.columns .item .creator {
  width: 10%;
  text-align: center;
  justify-content: center;
  display: flex;
}
.list .item .creator .picture,
.columns .item .creator .picture {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.list .item .contributors,
.columns .item .contributors {
  width: 15%;
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: center;
}
.list .item .contributors .avatars,
.columns .item .contributors .avatars {
  justify-content: center;
}
.list .item .tags,
.columns .item .tags {
  width: 15%;
  text-align: left;
}
.list .item .tags .MuiChip-root,
.columns .item .tags .MuiChip-root {
  margin-right: 0.3em;
}
.list .item .tags .tag,
.columns .item .tags .tag {
  display: inline-block;
  border-radius: 0.2em;
  padding: 0.2em 0.5em 0.2em 1em;
  color: #fff;
  margin-right: 0.4em;
  margin-bottom: 0.2em;
  position: relative;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  font-size: 0.9em;
}
.list .item .tags .tag:nth-child(2),
.columns .item .tags .tag:nth-child(2) {
  background-color: #322c31;
}
.list .item .tags .tag:nth-child(3),
.columns .item .tags .tag:nth-child(3) {
  background-color: #575267;
}
.list .item .tags .tag::before,
.columns .item .tags .tag::before {
  content: "";
  display: block;
  background-color: #fff;
  border-radius: 2em;
  position: absolute;
  width: 0.8em;
  height: 0.8em;
  left: -0.4em;
}
.list .item .tags .tagsLeft,
.columns .item .tags .tagsLeft {
  color: rgb(169, 169, 169);
}
.list .item .actions,
.columns .item .actions {
  text-align: right;
  align-items: flex-end;
  width: 15%;
}
.list .item .actions a,
.columns .item .actions a {
  margin-right: 2em;
  display: inline-block;
}
.list .item .due_date,
.list .item .size,
.list .item .priority,
.list .item .status,
.columns .item .due_date,
.columns .item .size,
.columns .item .priority,
.columns .item .status {
  width: 15%;
}
.list .item .due_date .normal,
.columns .item .due_date .normal {
  color: grey;
}
.list .item .due_date .past,
.columns .item .due_date .past {
  color: rgb(214, 50, 50);
}
.list .item .status .tag,
.list .item .priority .tag,
.columns .item .status .tag,
.columns .item .priority .tag {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.8em;
}
.list .item .status .tag.assigned,
.list .item .priority .tag.assigned,
.columns .item .status .tag.assigned,
.columns .item .priority .tag.assigned {
  border-color: #7bb9b3;
}
.list .item .status .tag.started,
.list .item .priority .tag.started,
.columns .item .status .tag.started,
.columns .item .priority .tag.started {
  border-color: #575267;
}
.list .item .status .tag.review,
.list .item .priority .tag.review,
.columns .item .status .tag.review,
.columns .item .priority .tag.review {
  border-color: #fccd87;
}
.list .item .status .tag.done,
.list .item .priority .tag.done,
.columns .item .status .tag.done,
.columns .item .priority .tag.done {
  border-color: #a9ce7d;
}
.list .item .status .tag.low,
.list .item .priority .tag.low,
.columns .item .status .tag.low,
.columns .item .priority .tag.low {
  border-color: #a9ce7d;
}
.list .item .status .tag.mid,
.list .item .priority .tag.mid,
.columns .item .status .tag.mid,
.columns .item .priority .tag.mid {
  border-color: #fccd87;
}
.list .item .status .tag.high,
.list .item .priority .tag.high,
.columns .item .status .tag.high,
.columns .item .priority .tag.high {
  border-color: #008683;
}
.list .item .task,
.columns .item .task {
  width: 40%;
}
.list .item .function,
.columns .item .function {
  width: 5%;
}
.list .item .company,
.columns .item .company {
  width: 30%;
}
.list .item .email,
.columns .item .email {
  width: 20%;
}
.list .item .lastname,
.list .item .firstname,
.list .item .phone,
.columns .item .lastname,
.columns .item .firstname,
.columns .item .phone {
  width: 15%;
}
.list .item .open_details_action,
.list .item .open_file_action,
.columns .item .open_details_action,
.columns .item .open_file_action {
  color: #575267;
  transition: 0.3s;
  cursor: pointer;
}
.list .item .open_details_action:hover,
.list .item .open_file_action:hover,
.columns .item .open_details_action:hover,
.columns .item .open_file_action:hover {
  color: #008683;
  text-decoration: underline;
}
.list .item a,
.columns .item a {
  cursor: pointer;
  color: #575267;
  transition: 0.3s;
}
.list .item a:hover,
.columns .item a:hover {
  color: #008683;
}
.list .item_with_subrows,
.columns .item_with_subrows {
  border-bottom: solid 1px rgb(224, 224, 224);
  padding: 1em 2em;
}
.list .item_with_subrows:last-child,
.columns .item_with_subrows:last-child {
  border-bottom: none;
}
.list .item_with_subrows .item,
.columns .item_with_subrows .item {
  padding: 0;
  margin-bottom: 0.5em;
  border-bottom: solid 1px rgb(247, 247, 247);
  padding-bottom: 0.4em;
}
.tableComponent {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;
}
.tableComponent .table {
  height: 100%;
}
.tableComponent .tableHeader {
  width: 100%;
  height: 5%;
  background-color: rgb(33, 33, 33);
  display: flex;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.tableComponent .tableHeader .column {
  padding: 10px 5px;
  color: #d5d5d5;
  font-weight: 500;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  position: relative;
}
.tableComponent .tableHeader .column.sortable {
  cursor: pointer;
}
.tableComponent .tableHeader .column.sortable .sort {
  position: absolute;
  right: 5px;
}
.tableComponent .tableHeader .column.min {
  flex: 0.5;
}
.tableComponent .tableHeader .column.center {
  justify-content: center;
  text-align: center;
}
.tableComponent .tableBody {
  width: 100%;
  height: 90%;
  overflow: auto;
  background-color: #fff;
  border-top: solid 1px rgb(247, 247, 247);
  border-bottom: solid 1px rgb(247, 247, 247);
}
.tableComponent .tableBody .row {
  width: 100%;
  display: flex;
  border-bottom: solid 1px rgb(247, 247, 247);
  align-items: center;
}
.tableComponent .tableBody .row:nth-child(2n) {
  background-color: rgb(247, 247, 247);
}
.tableComponent .tableBody .row .entry {
  padding: 5px;
  color: rgb(72, 72, 72);
  font-weight: 400;
  font-size: 0.9em;
  border-right: solid 1px rgb(247, 247, 247);
}
.tableComponent .tableBody .row .entry:last-child {
  border-right: none;
}
.tableComponent .tableBody .row .entry.center {
  text-align: center;
}
.tableComponent .tableBody .row .entry.min {
  flex: 0.5;
}
.tableComponent .tableBody .row .entry .entry_value {
  width: 100%;
  height: 100%;
  min-height: 15px;
  cursor: pointer;
}
.tableComponent .tableBody .row .entry .entry_edit {
  width: 100%;
}
.tableComponent .tableBody .row .entry .entry_edit input {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border: 2px solid #b8b8b8;
  border-radius: 0.2em;
  transition: 0.3s;
  font-size: 1em;
  color: rgb(72, 72, 72);
}
.tableComponent .tableBody .row .entry .entry_edit input:focus {
  border-color: #008683;
  outline: none;
}
.tableComponent .tableBody .row .entry .flagButton svg {
  font-size: 1.4em;
  color: rgb(198, 198, 198);
  cursor: pointer;
}
.tableComponent .tableBody .row .entry .flagButton.active svg {
  color: #a9ce7d;
}
.tableComponent .tableBody .row .entry a {
  color: rgb(72, 72, 72);
  transition: 0.3s;
}
.tableComponent .tableBody .row .entry a:hover {
  color: #008683;
}
.tableComponent .tableBody .row .entry .action {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: rgb(72, 72, 72);
  transition: 0.3s;
}
.tableComponent .tableBody .row .entry .action:hover {
  color: #008683;
}
.tableComponent .tableBody .row .entry .action svg {
  transform: scale(0.8);
}
.tableComponent .tableBody .row .entry .action.delete {
  color: #99201c;
}
.tableComponent .tableBody .row .actions {
  width: 100%;
}
.tableComponent .tableBody .row .actions button {
  display: inline-block;
  min-width: 48%;
  padding: 2%;
}
.tableComponent .tableBody .row .actions button.error {
  color: #99201c !important;
}
.tableComponent .tableBody .row .actions button svg {
  font-size: 1.4em;
}
.tableComponent .tableFooter {
  padding: 15px;
  height: 6%;
  background-color: #fff;
  box-sizing: border-box;
}
.tableComponent .tableFooter .pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tableComponent .tableFooter .pagination button,
.tableComponent .tableFooter .pagination span {
  cursor: pointer;
  transition: 0.3s;
  background-color: transparent;
  width: 45px;
  font-size: 0.9em;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0 0.1em;
}
.tableComponent .tableFooter .pagination button:hover,
.tableComponent .tableFooter .pagination span:hover {
  background-color: #eee;
  color: #008683;
  border-radius: 0.2em;
}
.tableComponent .tableFooter .pagination button.disabled,
.tableComponent .tableFooter .pagination span.disabled {
  color: rgb(181, 181, 181);
  cursor: initial;
}
.tableComponent .tableFooter .pagination button.disabled:hover,
.tableComponent .tableFooter .pagination span.disabled:hover {
  background-color: buttonface !important;
}
.tableComponent .tableFooter .pagination .active {
  cursor: default;
  color: #fff;
  background-color: #008683;
  border-radius: 0.2em;
}
.tableComponent .tableFooter .pagination .active:hover {
  background-color: #008683;
}
@media all and (max-width: 1450px) {
  .tableComponent .tableFooter {
    height: 10%;
  }
  .tableComponent .tableFooter button,
  .tableComponent .tableFooter span {
    height: 25px !important;
  }
}

.tableLoader {
  position: relative;
  width: 100%;
}
.tableLoader .loader {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  background-color: transparent !important;
  padding-top: 2em;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.row.reverse {
  flex-direction: row-reverse;
}
.row .col {
  position: relative;
  margin: 0 0.2em;
}
.row .col:first-child {
  margin-left: 0;
}
.row .col:last-child {
  margin-right: 0;
}
@media all and (max-width: 991px) {
  .row {
    flex-direction: column;
  }
}

.col {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.col.reverse {
  flex-direction: column-reverse;
}
.col.md4 {
  width: 25%;
  flex: 0.25;
}
.col.md6 {
  width: 50%;
  flex: 0.5;
}
.col.md8 {
  width: 75%;
  flex: 0.75;
}

.textField {
  border: solid 1px rgb(222, 222, 222);
  border-radius: 0.2em;
  width: 100%;
  position: relative;
  margin: 1em 0;
  z-index: 1;
}
.textField label {
  position: absolute;
  color: rgb(199, 199, 199);
  padding: 0 0.3em;
  z-index: 0;
  left: 15px;
  height: 100%;
  font-size: 0.8em;
  transition: 0.3s;
  top: 0;
  display: flex;
  align-items: center;
}
.textField input {
  border: none;
  outline: none;
  width: 94%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  background: transparent;
  height: 100%;
  padding: 0.8em 15px;
  font-size: 0.95em;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: rgb(35, 35, 35);
}
.textField button,
.textField .icon {
  width: 6%;
  height: 100%;
  text-align: right;
  border: none;
  background: transparent;
  font-size: 0.8em;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textField button svg,
.textField .icon svg {
  transform: scale(0.7);
  transition: 0.3s;
  fill: grey;
}
.textField button :hover {
  cursor: pointer;
}
.textField button :hover svg {
  fill: #008683;
}
.textField:focus label, .textField:focus-visible label, .textField:focus-within label, .textField.complete label {
  transform: scale(0.85) translateY(-67%) translateX(-10%);
  color: #008683;
  background-color: #fff;
}
.textField.error {
  border: solid 1px #99201c;
}
.textField.disabled label,
.textField.disabled input {
  color: #9b9b9b;
}
.textField.disabled input {
  font-style: italic;
}

.react-select__control.react-select__control--is-focused {
  border-color: #008683 !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-select__control .react-select__option--is-selected {
  background-color: #008683 !important;
  color: #fff !important;
}
.react-select__control .react-select__menu-list .react-select__option.option--is-selected,
.react-select__control .react-select__menu-list .react-select__option .option--is-selected {
  background-color: #008683 !important;
  color: #fff !important;
}

.MuiDataGrid-root {
  height: 78% !important;
  background: #FFF !important;
  border-radius: 0.6em !important;
}
@media all and (max-width: 1450px) {
  .MuiDataGrid-root {
    height: 75% !important;
  }
}

.MuiDataGrid-footerContainer {
  display: flex;
  flex-direction: row-reverse;
}

.MuiButton-containedPrimary:not(.Mui-disabled) {
  background-color: #575267 !important;
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
  color: #322c31 !important;
}
.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root).MuiButton-outlined {
  border-color: #322c31 !important;
}
.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root).MuiButton-textError {
  color: #008683 !important;
}
.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root).MuiButton-textError.MuiButton-outlined {
  border-color: #008683 !important;
}

.errroNotification .MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
  color: #fff !important;
}

.MuiModal-backdrop:not(.MuiBackdrop-invisible) {
  background-color: rgba(50, 44, 49, 0.9) !important;
}

#form-file-upload {
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 10em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  transition: 0.3s;
}
#label-file-upload:hover {
  border-color: #008683;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  background-color: #008683;
  color: #FFF;
  border-radius: 1rem;
  cursor: pointer;
  padding: 5px 15px;
  font-size: 14px;
  border: none;
  text-decoration: none !important;
  text-transform: uppercase;
}
.upload-button:hover {
  transform: scale(1.02);
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.uploadGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.uploadGallery img {
  margin: 3px;
  width: 75px;
  height: 75px;
  -o-object-fit: cover;
     object-fit: cover;
}

.mediaList,
.medias {
  width: 100%;
  height: 100%;
  max-height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mediaList .loading,
.medias .loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10em;
}
.mediaList .picture_table,
.medias .picture_table {
  height: 80px;
  margin: 4px;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mediaList .picture_table img,
.medias .picture_table img {
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  transition: 0.1s;
  border: solid 2px rgb(207, 207, 207);
  margin-right: 10px;
}
.mediaList .picture_table span,
.medias .picture_table span {
  font-weight: bold;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  width: 25vw;
}

.medias {
  list-style: none;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  max-height: none;
  height: auto;
  overflow: hidden;
}
.medias li {
  flex: 1;
  width: 98% !important;
  padding: 10px;
  border: none;
  box-sizing: border-box;
  border-bottom: solid 1px #eee;
  display: flex;
  flex-direction: row;
}
.medias li img {
  width: 100px !important;
  height: 100px !important;
}
.medias li .infos {
  flex-grow: 1;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.medias li .infos strong {
  font-size: 1.2em;
  margin-bottom: 10px;
}
.medias li .icons {
  display: flex;
  width: 100px;
  flex-direction: row;
  align-items: center;
}
.medias li .icons svg {
  width: 30px !important;
  height: 30px !important;
  margin: 10px;
}
.medias li .icons .delete {
  position: relative;
}

.selector {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.selector strong {
  flex: 0.5;
}
.selector span {
  flex: 0.4;
  color: grey;
}
.selector .select_button {
  flex: 0.1;
  text-align: right;
}

.lightbox {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.lightbox.open {
  opacity: 1;
  visibility: visible;
}

/* Global styles */
body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  font-feature-settings: "pnum";
  font-size: 14px;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  color: #3b3b3b;
}

.emptyMessage {
  color: rgb(217, 217, 217);
  text-align: center;
  padding: 2em;
  display: block;
  font-size: 2em;
  font-weight: 300;
}

.error_message {
  color: #99201c;
}

.success_message {
  color: #a9ce7d;
}

.applicationContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  padding: 0 2em 0 0;
  overflow: hidden;
  background-color: #fff;
}
@media all and (max-width: 768px) {
  .applicationContainer {
    padding: 0;
  }
}

.spacer {
  width: 20px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgb(225, 225, 225);
  margin: 1em 0;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  position: fixed;
  z-index: 9;
  background-color: #fff;
}
.loader svg {
  color: grey;
}

.lost {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lost strong {
  font-size: 5em;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0.4em;
}
.lost span {
  font-size: 2em;
  color: #c2c2c2;
  display: inline-block;
  margin-bottom: 0.4em;
}
.lost a {
  color: #008683;
}

/* Font weight */
strong,
h2,
th {
  font-weight: 600;
}

/* scrollbar */
/* width */
body {
  scrollbar-color: #888 #f1f1f1;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.select_label {
  color: #008683;
  font-size: 0.7em;
  padding: 0.3em 1em;
  background-color: #fff;
  position: relative;
  display: inline-block;
  width: 4em;
  top: 0em;
  z-index: 2;
  margin-bottom: -0.4em;
  left: 0.5em;
}

.appLoader {
  position: fixed !important;
  top: 0;
  width: 100vw;
  z-index: 99 !important;
  background-color: rgba(0, 134, 131, 0.2) !important;
  transition: 0.3s;
}
.appLoader.done {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4px);
}
.appLoader span {
  background-color: #008683;
}

.loading_skeleton {
  padding: 3em 0;
  opacity: 0.5;
}
.loading_skeleton .row {
  flex: 0.05;
}

.deviceView {
  display: none;
}
@media all and (max-width: 991px) {
  .deviceView {
    display: flex;
    box-sizing: border-box;
    padding: 3em;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  .deviceView img {
    width: 10em;
    max-width: 90%;
  }
}

.desktopView {
  display: block;
}
@media all and (max-width: 991px) {
  .desktopView {
    display: none;
  }
}

/* Global styles */
.link {
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  color: #626262;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #888;
  box-shadow: 1px 1px 10px -7px rgb(0, 0, 0);
  transition: 0.1s;
}
.link:hover {
  box-shadow: 0px 4px 10px -7px rgb(0, 0, 0);
}
.link.add {
  background-color: #a9ce7d;
  color: #fff;
}/*# sourceMappingURL=App.css.map */